// normal styles ===================================
@mixin collapse-sidebar-icon-style($left) {
	cursor: pointer;
	position: absolute;
	bottom: 0%;
	height: 40px;
	width: 40px;
	left: $left;
	border-radius: 50%;
	background-color: var(--primary-theme);
	box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
	transition: all 0.2s ease-in-out;
	margin-bottom: 8px;

	img {
		&:hover {
			box-shadow: 27px 21px 38px -17px rgba(175, 162, 193, 0.44);
			background-color: var(--primary-theme);
			color: var(--sidebar-theme);
		}
	}

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.246) 0px 4px 12px;
	}
}

@mixin collapse-sidebar-Editprofile-style($left) {
	cursor: pointer;
	position: absolute;
	bottom: 0%;
	// height: 40px;
	// width: 40px;
	// left: $left;
	// border-radius: 50%;
	// background-color: var(--primary-theme);
	// box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
	// transition: all 0.2s ease-in-out;
	// margin-bottom: 40px;
	width: 100%;
	// img {
	// 	&:hover {
	// 		box-shadow: 27px 21px 38px -17px rgba(175, 162, 193, 0.44);
	// 		background-color: var(--primary-theme);
	// 		color: var(--sidebar-theme);
	// 	}
	// }
	// &:hover {
	// 	box-shadow: rgba(0, 0, 0, 0.246) 0px 4px 12px;
	// }
}

.Sidebar {
	.iconColor {
		color: #9149ff;
		// background-color: #9149ff;
	}

	.icon {
		@include collapse-sidebar-Editprofile-style(180px);
	}

	&__collapseButton.open {
		@include collapse-sidebar-icon-style(180px);
	}

	&__collapseButton.close {
		@include collapse-sidebar-icon-style(20px);
	}

	.Sidebar__listClosed {
		padding-bottom: 0px;
		padding-top: 0px;
	}

	&__OpenMenuButton {
		margin-right: 28px !important;
		margin-top: 120px !important;

		svg {
			color: rgba(0, 0, 0, 0.257);
			position: absolute;
			top: 5px;
			border-radius: 50%;
			border: 0.5px solid rgba(0, 0, 0, 0.312);

			&:hover {
				background-color: var(--primary-theme);
				color: var(--sidebar-theme);
			}
		}
	}

	&:hover {
		.Sidebar__collapseButton {
			opacity: 1;
		}
	}

	&__appbar {
		background-color: white !important;
		box-shadow: none !important;
		width: calc(100% - var(--sidebar-width)) !important;
		// width: calc(100% - 90px) !important;
		overflow: hidden;
		float: left;

		.MuiToolbar-root {
			min-height: auto !important;
		}
	}

	&__appbarClosed {
		background-color: white !important;
		box-shadow: none !important;
		// width: calc(100% - var(--sidebar-width)) !important;
		width: calc(100% - 80px) !important;
		overflow: hidden;
		float: left;

		.MuiToolbar-root {
			min-height: auto !important;
		}
	}

	&__MenuIcon {
		background-color: none;
	}

	&__logoContainer {
		justify-content: center;
		display: flex;
		width: -webkit-fill-available;
		max-width: 220px;
		align-items: center;
		height: 5.5rem;
	}

	&__logoContainerClosed {
		justify-content: center;
		display: flex;
		align-items: flex-end;
		width: -webkit-fill-available;
		height: 5.5rem;

		img {
			margin-bottom: 22px;
		}

		// display: none;
		// height: 100px;
		// width: 100px;
		// img{
		//   position: absolute;
		//   left: 15%;
		// }
	}

	&__drawer {
		width: var(--sidebar-width);
		flex-shrink: 1;
	}

	&__lineSelector {
		position: absolute;
		height: 100%;
		left: 0px;
		border-right: 4px solid var(--primary-theme);
		border-radius: 0px 24px 24px 0px;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--page-bg-color) !important;
		height: 152px !important;
		color: var(--primary-theme);
		font-weight: normal !important;
		letter-spacing: 0.19px;
	}

	&__list {
		padding: 0px !important;
	}

	&__divider {
		// transition: all 0.32s;
		outline: none;
		// background-color: #dadada;
		// background-color: #2f2f2f;
		height: 1px;
		// margin: 0.1rem 0;
		margin-left: 0px;
		padding: 0 !important;
		// opacity: 0.5;
	}

	&__item {
		padding: 0.8rem 1rem !important;
		margin: 0.2rem 0rem !important;
		position: relative;
		cursor: pointer;
		background-color: inherit !important;

		&__svg {
			&__selected {
				path {
					// fill: var(--primary-theme);
					stroke: #9149ff;
				}

				#Oval-2 {
					fill: var(--primary-theme);
				}
			}
		}

		&__text {
			font-size: 18px;
			margin-left: 0.2rem;

			.MuiTypography-body1 {
				font-size: 1rem;
				font-weight: inherit;
			}
		}

		&__textOverflow {
			text-overflow: ellipsis;
			overflow: hidden;
			width: calc(100% - 10px);
		}

		&__SubText {
			// margin-left: 0.8rem;
			// border: 2px solid red;
			cursor: pointer;

			span {
				&:hover {
					color: var(--primary-theme);
				}
			}

			.MuiTypography-body1 {
				font-size: 15px;
				font-weight: inherit;
				margin-right: 10px;
			}
		}
	}

	&__subItem {
		position: relative;
		// padding: 0.2rem 1rem !important;
		// padding: 0.2rem 2rem !important;
		padding: 0.2rem 0rem 0.2rem 2.5rem !important;
		margin: 0;
	}

	&__subCategoryList,
	&__subCategoryListClosed,
	&__subCategoryListDark,
	&__subCategoryListClosedDark {
		// border: 2px solid red;
		position: absolute;
		left: 236px;
		// background-color: white;
		min-width: 17rem;
		border-radius: 10px;
		position: fixed;
		display: flex;
		align-items: center;
		min-height: 0px !important;
		max-height: 450px !important;
		overflow-y: scroll !important;
		transition-duration: 300ms;
		// ************** more prominent box shadow -: commented as of now
		// box-shadow: var(--card-shadow);
		// -webkit-box-shadow: 10px 10px 12px -5px rgba(232, 217, 255, 0.71);
		// -moz-box-shadow: 10px 10px 12px -5px rgba(232, 217, 255, 0.71);
		// box-shadow: 10px 10px 12px -5px rgba(232, 217, 255, 0.71);
		// *************** lighter box shadow - currently active -:
		// -webkit-box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
		// -moz-box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
		// box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
		// +++++++++++++++++++++++++++=
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

		.MuiCollapse-wrapper {
			max-height: 450px;
			min-height: 0px;
		}
	}

	&__subCategoryListClosed,
	&__subCategoryListClosedDark {
		min-height: 0px !important;
		max-height: 450px !important;
		left: 90px;
		overflow-y: scroll !important;
		transition-duration: 300ms;

		// height: -webkit-fill-available !important;
		// padding-bottom: 20px;
		// transition: height 0.3s ease-in-out;
		.MuiCollapse-wrapper {
			max-height: 450px;
			min-height: 0px;
		}
	}
}

.Sidebar__subCategoryList .MuiList-padding,
.Sidebar__subCategoryListDark .MuiList-padding {
	padding: 0.5rem 0rem 0.5rem !important;
}

.Sidebar__drawer .MuiDrawer-paper {
	// width: var(--sidebar-width);
	// border-right: 0px !important;
	margin-left: 0.1rem;
	// height: calc(100vh - 1rem) !important;
	border-radius: 6px !important;
	visibility: inherit !important;
	transform: none !important;
}

.Sidebar__list .MuiListItemIcon-root,
.Sidebar__listDark .MuiListItemIcon-root {
	min-width: 1.5rem;
	min-height: 1.5rem;
	border-radius: 6px;
	margin-right: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Sidebar__listClosed .MuiListItemIcon-root,
.Sidebar__listClosedDark .MuiListItemIcon-root {
	// min-width: 1.5rem;
	min-height: 1.5rem;
	border-radius: 6px;
	margin-right: 10px !important;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 2.5rem;
}

.Sidebar__selected .Mui-selected {
	color: #9149ff;
}

// !here
.Sidebar__list .Mui-selected,
.Sidebar__listClosed .Mui-selected {
	// background-color: white !important;
	background-color: white;
}

.Sidebar .MuiListItem-root:hover {
	.Sidebar__item__text .MuiTypography-body1 {
		color: var(--primary-theme);
	}

	// background-color: var(--sidebar-theme);
	opacity: 0.9;
}

// !here
.Sidebar__subCategoryList .Mui-selected,
.Sidebar__subCategoryListClosed .Mui-selected {
	color: #9149ff !important;
	// background-color: var(--sidebar-theme) !important;
	background-color: transparent !important;
}

.Sidebar__subCategoryListDark .Mui-selected,
.Sidebar__subCategoryListClosedDark .Mui-selected {
	color: var(--sidebar-theme) !important;
	// background-color: var(--sidebar-theme) !important;
	background-color: transparent !important;
}

.Sidebar {
	&__drawer_open {
		// width: var(--sidebar-width);
		overflow-x: hidden;
		transition: width 0.5s;
	}

	&__drawer_close {
		width: 80px !important;
		overflow-x: hidden;
		transition: all 1.5s ease-out;
		transition-timing-function: ease;
	}
}

// =============================

@media screen and (max-width: 1372px) {
	.Sidebar {
		&__item {
			padding: 0.3rem 1rem !important;
			padding-right: 28px !important;
			padding-left: 1rem !important;

			&__text {
				// font-size: 1rem;
				margin-top: 3px;
				margin-bottom: 3px;
			}

			svg {
				width: 21px;
				height: 21px;
			}
		}

		&__subItem {
			svg {
				width: 19px;
				height: 19px;
			}
		}

		&__subCategoryList {
			// font-size: 12px;
			min-width: 16rem;
			left: 228px;
		}

		&__subCategoryListDark {
			// font-size: 12px;
			min-width: 16rem;
			left: 228px;
		}

		&__subCategoryListClosed {
			min-width: 16rem;
			left: 86px;
		}

		&__subCategoryListClosedDark {
			min-width: 16rem;
			left: 86px;
		}

		&__header {
			height: 138px !important;
		}

		&__appbar {
			.MuiToolbar-root {
				padding-left: 0.5rem !important;
				padding-right: 0.5rem !important;
			}
		}
	}
}

@media screen and (min-width: 1372px) {
	.Sidebar {
		&__logoContainer,
		&__logoContainerClosed {
			height: 4.5rem;
		}
	}
}

.Sidebar__listClosed,
.Sidebar__listClosedDark {
	ul {
		.Sidebar__item {
			margin-left: 0rem !important;

			padding-left: 0rem !important;
			padding-right: 0rem !important;
		}

		.Sidebar__subItem {
			.sidebar_svg {
				min-width: 1.5rem;
			}
		}
	}
}
