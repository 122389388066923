// Change this for page headings
.app {
	font-family: Lato !important;
	display: flex;

	&__outer {
		width: 100%;
		height: 100vh;
		background-color: rgb(244, 247, 252) !important;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// margin-top: 3.5rem;
		overflow-x: hidden;
		// Firefox Scrollbar
		scrollbar-color: var(--scrollbar-color) transparent !important;
		scrollbar-width: thin;
	}

	&__embeded {
		height: 100vh !important;
		margin-top: 0rem !important;
	}
}

.commonPageHeading {
	color: var(--pageHeading);
	padding: 0.5rem 1rem;
	font-size: 24px;
	font-weight: 600;
	height: 70px;
	background-color: white;
	justify-content: space-between;
	align-items: center;
	display: flex;
	box-shadow: 0px 5px 12px #0000001a;

	// box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}

.pageheadingFixed {
	position: fixed;
	display: flex;
	align-items: center;
	// width: calc(98% - var(--sidebar-width));
	width: -webkit-fill-available;
	width: -moz-available;
	z-index: 2;
}

.marginForFixedHeading {
	margin-top: 6rem;
	overflow-y: auto;
	height: calc(100% - 6rem);
	width: 100%;
	background-color: rgb(244, 247, 252) !important;

	
}

.commonSubHeading {
	// color: var(--subHeading);
	padding: 1rem;
	font-size: 20px;
	float: left;
	font-weight: 600;
}

.notDataFoundList {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: darkslategrey;
	overflow: hidden;
}

.notDataFoundList .MuiSvgIcon-root {
	color: slategray;
}

@media screen and (max-width: 1366px) {
	.commonPageHeading {
		height: 88px;
	}

	.marginForFixedHeading {
		margin-top: 6rem;
		// commonpage heading - padding - header
		height: calc(100% - 6rem);
		width: 100% !important;
	}
}

::-webkit-scrollbar {
	// width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--scrollbar-color);
	border-radius: 10px;
}

.MuiPaper-root {
	border-radius: 15px !important;
}
.MuiInputBase-root {
	border-radius: 15px !important;
}

*{
	font-family: Lato !important;

}
